
  import { mapState } from 'vuex'

  export default {
    props: {
      option: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState({
        currency: state => state.localization.currency,
      }),
      formattedPrice() {
        return this.$currencyService.formatToLocalePrice(this.option.price, this.currency)
      },
    },
  }
