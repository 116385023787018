
  import debounce from 'lodash.debounce'

  export default {
    data() {
      return {
        contentHeight: 0,
        showContent: false,
        onResizeDebounce: debounce(this.onResize, 100),
      }
    },
    computed: {
      icon() {
        return this.showContent
          ? 'min'
          : 'plus'
      },
    },
    mounted() {
      this.setExpandedHeight()
      window.addEventListener('resize', this.onResizeDebounce)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResizeDebounce)
    },
    methods: {
      toggleShowContent() {
        this.showContent = !this.showContent
      },
      setExpandedHeight() {
        const contentElement = this.$refs['accordion-content']

        if (!contentElement) {
          return
        }

        const propertyValue = getComputedStyle(contentElement).getPropertyValue('--accordion-bottom-spacing')
        const bottomSpacing = parseInt(propertyValue)

        this.contentHeight = `${contentElement.scrollHeight + bottomSpacing}px`
      },
      onResize() {
        this.showContent = false
        this.setExpandedHeight()
      },
    },
  }
