import { render, staticRenderFns } from "./return-option-description.vue?vue&type=template&id=ca00525a"
import script from "./return-option-description.vue?vue&type=script&lang=js"
export * from "./return-option-description.vue?vue&type=script&lang=js"
import style0 from "./return-option-description.vue?vue&type=style&index=0&id=ca00525a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppAccordion: require('/opt/build/repo/src/client/components/app-accordion/app-accordion.vue').default})
