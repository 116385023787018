
  import AppImage from '@loavies/component-library/dist/app-image'

  export default {
    components: {
      AppImage,
    },
    props: {
      instruction: {
        type: Object,
        required: true,
      },
    },
    computed: {
      imageUrl() {
        return this.instruction?.image?.url
      },
    },
  }
