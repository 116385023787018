import { render, staticRenderFns } from "./index.vue?vue&type=template&id=329d3068"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=329d3068&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextBlockItem: require('/opt/build/repo/src/client/components/text-block-item/text-block-item.vue').default,ReturnsInstruction: require('/opt/build/repo/src/client/components/returns-instruction/returns-instruction.vue').default,HorizontalCarousel: require('/opt/build/repo/src/client/components/horizontal-carousel/horizontal-carousel.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default,ReturnOption: require('/opt/build/repo/src/client/components/return-option/return-option.vue').default,ReturnOptionDescription: require('/opt/build/repo/src/client/components/return-option-description/return-option-description.vue').default})
