
  import { mapGetters } from 'vuex'

  import formatSeoHeadForPage from '~/lib/format-seo-head-for-page'
  import formatCanonicalForRoute from '~/lib/format-canonical-for-route'
  import getDatoPageData from '~/lib/get-dato-page-data'
  import notFoundError from '~/lib/errors/not-found'
  import { GLOBAL_E_RETURNS_PAGE } from '~/lib/constants'

  export default {
    async asyncData(context) {
      try {
        const data = await getDatoPageData(context)

        if (!data?.page) {
          return context.error(notFoundError)
        }

        return data
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(`Error while generating returns page:`, error)
        }

        return context.error(notFoundError)
      }
    },
    data() {
      return {
        globalEReturnsPage: GLOBAL_E_RETURNS_PAGE,
      }
    },
    head() {
      const seoHead = formatSeoHeadForPage(this.page.seo, this.$route)
      const canonical = formatCanonicalForRoute(this.$route.path)

      return Object.assign(seoHead, canonical)
    },
    computed: {
      ...mapGetters({
        isGlobalECustomer: 'localization/isGlobalECustomer',
      }),
      customerServiceSlug() {
        return this.$staticDataService.cmsTranslatedSlugs?.['customer-service'][this.$i18n.locale]
      },
    },
  }
